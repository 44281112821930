.heart {
  width: 16px;
  height: 16px;
  fill: #ff6b09;
}
.heartWrapper {
  vertical-align: middle;
}
.footer {
  height: 56px;
  background-color: #02172a;
  padding: 14px 20px;
  text-align: center;
}

.infoUnit,
.author {
  font-family: "Montserrat-400", sans-serif;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 0.03em;
}

.infoUnit {
  position: relative;
}

.infoUnit:not(:last-child) {
  margin-right: 13px;
}

.infoUnit:not(:last-child)::after {
  content: "";
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  opacity: 0.5;
  height: 13px;
  position: absolute;
  right: -7px;
  top: 0;
}

.navLink {
  color: rgba(255, 255, 255, 0.4);
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
  }

  .infoUnit:last-child {
    margin-right: 5px;
  }

  .infoUnit,
  .author {
    font-size: 12px;
  }
}
