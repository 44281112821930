.loader {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
}

.modalLoader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}