/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat-400';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/montserrat-v15-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat-500';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../fonts/montserrat-v15-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v15-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat-700';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../fonts/montserrat-v15-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat-800';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('../fonts/montserrat-v15-latin-800.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v15-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
